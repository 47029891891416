import Navigation from './Navigation';
import { Provider } from './utils/context';

function App() {

  return (
    <Provider>
      <Navigation/>
    </Provider>
  );
}

export default App;
