/* eslint-disable react-hooks/rules-of-hooks */
import React, {createContext, useState} from 'react';

export const Context = createContext({
  user: {} as any,
  width: {} as any,
  height: {} as any,
  selectedOrg: {} as any,
  selectedPlayer: {} as any,
  HandleSignOut: () => {},
  HandleUser: (param: string, data?: string) => {},
  SelectOrg:(param: any) => {},
  SelectPlayer:(param: any) => {}
});

export const Provider = ({children}: {children: any}) => {
  const [user, setUser] = useState<any>(localStorage.user ? JSON.parse(localStorage.user) : {});
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const [selectedOrg, setSelectedOrg] = useState(localStorage.org? localStorage.org : null);
  const [selectedPlayer, setSelectedPlayer] = useState(localStorage.player? localStorage.player : null);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  function HandleUser(param: any, data?: any): void {
    if (data) {
      param.data = data;
    }
    setUser(param);
    localStorage.setItem('user', JSON.stringify(param));
  }


  function HandleSignOut(): void {
    localStorage.removeItem('user');
    setUser({});
  }

  function SelectOrg(param: any): void{
    localStorage.setItem('org', JSON.stringify(param))
    setSelectedOrg(param);
  }
  
  function SelectPlayer(param: any): void{
    localStorage.setItem('player', JSON.stringify(param))
    setSelectedPlayer(param);
  }

  return (
    <Context.Provider
      value={{
        user,
        width,
        height,
        selectedOrg,
        selectedPlayer,
        HandleUser,
        HandleSignOut,
        SelectOrg,
        SelectPlayer,
      }}>
      {children}
    </Context.Provider>
  );
};

export const useViewport = () => {
  const { width, height } = React.useContext(Context);
  return { width, height };
}