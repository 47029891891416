import React, { Suspense, lazy  } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Context } from './utils/context';

const Login = lazy(() => import('./pages/login/login'));
const Board = lazy(() => import('./pages/board/board'));

function Navigation() {

  const { user } = React.useContext(Context);

  return (
      <BrowserRouter>
        <Suspense fallback={<div></div>}>
          <Routes>
            {user && user?.data && user?.data.isRoot ? 
            <Route path="/*" element={<Board />}  />:
            <Route path="/*" element={<Login />} />}
          </Routes>
        </Suspense>
      </BrowserRouter>
  );
}

export default Navigation;
